<template>
  <div :class="`login ${isError ? '-error' : ''}`">
    <div
      class="_logo"
      v-if="__projectConfig.assets?.logo"
      v-html="__projectConfig.assets.logo"
    ></div>

    <div class="_form">
      <h3 class="_title">
        {{ $t('login.title') }}
      </h3>
      <input
        ref="$password"
        type="password"
        :class="`form-input ${isError ? '-invalid' : ''}`"
        v-model="password"
        :placeholder="$t('login.password')"
        @keypress.enter="
          (e) => {
            login();
          }
        "
      />
      <input
        type="button"
        :class="`button ${isError ? '-error' : ''}`"
        :value="$t('login.login')"
        @click="login"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useTemplateRef, onMounted } from 'vue';
import __projectConfig from '~/project.config.js';

let isError = ref(false);
const $password = useTemplateRef('$password');
const userStore = useUserStore();
let password: string;

onMounted(() => {
  // @ts-ignore
  $password.value.focus();
});

async function login() {
  const response = await userStore.login(password);
  if (!response.success) {
    isError.value = true;
  } else {
    isError.value = false;
  }
}
</script>
