import __gsap from 'gsap';
import __ScrollTrigger from 'gsap/ScrollTrigger';
import __Flip from 'gsap/Flip';
import __Draggable from 'gsap/Draggable';
import __MotionPath from 'gsap/MotionPathPlugin';

export default function __initGsap(): typeof __gsap {
  // register plugins
  __gsap.registerPlugin(__ScrollTrigger, __Draggable, __Flip, __MotionPath);

  // set lag smoothing
  __gsap.ticker.lagSmoothing(0);

  // return the instance
  return __gsap;
}
