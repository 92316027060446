import { defineStore } from 'pinia';
import {
  __deleteUserCookie,
  __getUserCookie,
} from '~/lib/user/user.utils.js';

import __projectConfig from '~/project.config.js';

export type TUserStore = {
  isAdmin: boolean;
  apiToken: string | undefined;
  login: (password: string) => Promise<TUserStoreLoginResponse>;
  logout: () => Promise<TUserStoreLogoutResponse>;
  restore: () => void;
  enableDraftMode: () => void;
  disableDraftMode: () => void;
  setDraftMode: (value: boolean) => void;
  isDraftMode: boolean;
};

export type TUserStoreLoginResponse = {
  success: boolean;
};

export type TUserStoreLogoutResponse = {
  success: boolean;
};

export const useUserStore = defineStore('user', () => {
  let isAdmin = false,
    apiToken: string | undefined,
    draftMode = false;

  let userCookie;

  const config = useRuntimeConfig();
  const draftModeCookie = useCookie(__projectConfig.cookies?.draftModeCookieName ?? '__draftMode');

  /**
   * Restore the user state from the admin cookie.
   */
  function restore() {
    userCookie = __getUserCookie();
    isAdmin = !!userCookie;
    apiToken = (userCookie?.token ?? config.public.apiPublicToken) as string;
    if (draftModeCookie.value) {
      draftMode = true;
    }
  }

  /**
   * Logs in the user as an admin.
   * @param password The password to use for the login
   */
  async function login(password: string): Promise<TUserStoreLoginResponse> {
    // try to login from the server
    const response = await fetch('/api/user/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          password,
        }),
      }),
      data = await response.json();

    if (data.success) {
      const userCookie = __getUserCookie();
      if (!userCookie) {
        return {
          success: false,
        };
      }
      isAdmin = true;
      apiToken = userCookie.token;
      document.location.reload();
      return {
        success: true,
      };
    }
    return {
      success: false,
    };
  }

  /**
   * Logs out the user.
   */
  async function logout(): Promise<TUserStoreLogoutResponse> {
    __deleteUserCookie();
    document.location.reload();
    return {
      success: true,
    };
  }

  /**
   * Enable draft mode
   */
  function enableDraftMode(): void {
    if (isAdmin) {
      draftMode = true;
      draftModeCookie.value = 'true';
      if (import.meta.browser) {
        document.location.reload();
      }
    }
  }

  /**
   * Disable draft mode
   */
  function disableDraftMode(): void {
    draftMode = false;
    draftModeCookie.value = null;
    if (import.meta.browser) {
      document.location.reload();
    }
  }

  /**
   * Set draft mode
   */
  function setDraftMode(value: boolean): void {
    if (value) {
      enableDraftMode();
    } else {
      disableDraftMode();
    }
  }

  // restore the user state
  restore();

  return {
    isAdmin,
    apiToken,
    logout,
    login,
    restore,
    enableDraftMode,
    disableDraftMode,
    setDraftMode,
    get isDraftMode(): boolean {
      return isAdmin && draftMode;
    },
  } as TUserStore;
});