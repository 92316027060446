import __projectConfig from '~/project.config.js';
import { generateToken as __generateToken } from '~/server/lib/jwt/jwt.js';
import { jwtDecode } from 'jwt-decode';
import { type TUserCookiePayload } from './user.type.js';

export function __getUserCookie(): TUserCookiePayload | undefined {
  const config = useRuntimeConfig();
  const cookie = useCookie(config.public.userCookieName);
  if (!cookie.value) {
    return;
  }
  const decoded = jwtDecode(cookie.value as string) as TUserCookiePayload;
  return decoded;
}

export function __deleteUserCookie(): void {
  const config = useRuntimeConfig();
  const cookie = useCookie(config.public.userCookieName);
  cookie.value = null;
}
