import './lenis.css';
import { __gsap } from '~/lib/gsap/gsap.js';
import __lenis from 'lenis';

export default function init(): __lenis {
  // create a lenis instance
  const lenis = new __lenis();

  // gsap
  __gsap.ticker.add((time) => {
    lenis.raf(time * 1000);
  });

  // debug
  // lenis.on('scroll', (e) => {
  //   console.log(e);
  // });

  // animation frame loop
  function raf(time: number): void {
    lenis.raf(time);
    requestAnimationFrame(raf);
  }
  requestAnimationFrame(raf);

  return lenis;
}
