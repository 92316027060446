import { default as _91slug_93hLi4EZUtPAMeta } from "/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/[slug].vue?macro=true";
import { default as component_45stubvCsOzZ3XFUMeta } from "/builds/buzzbrothers/skeleton/nuxt-skeleton/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubvCsOzZ3XFU } from "/builds/buzzbrothers/skeleton/nuxt-skeleton/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___fr",
    path: "/:slug()",
    meta: _91slug_93hLi4EZUtPAMeta || {},
    component: () => import("/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/[slug].vue")
  },
  {
    name: "slug___en",
    path: "/en/:slug()",
    meta: _91slug_93hLi4EZUtPAMeta || {},
    component: () => import("/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/[slug].vue")
  },
  {
    name: component_45stubvCsOzZ3XFUMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubvCsOzZ3XFU
  },
  {
    name: component_45stubvCsOzZ3XFUMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubvCsOzZ3XFU
  },
  {
    name: component_45stubvCsOzZ3XFUMeta?.name,
    path: "/fr-sitemap.xml",
    component: component_45stubvCsOzZ3XFU
  },
  {
    name: component_45stubvCsOzZ3XFUMeta?.name,
    path: "/en-sitemap.xml",
    component: component_45stubvCsOzZ3XFU
  }
]